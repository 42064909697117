import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Seo from "../components/Seo"

const CookiesWrapper = styled.section`
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
  font-family: "Montserrat", sans-serif;
  padding-top: 4rem;
`
const StyledH1 = styled.h1`
  padding: 0.5rem 1rem;
`
const StyledH2 = styled.h2`
  padding: 0.5rem 1rem;
`
const StyledP = styled.p`
  padding: 0.5rem 1rem;
`
const StyledUl = styled.ul`
  list-style: none;
`
const StyledLi = styled.li`
  padding: 0.5rem 1rem;
`
const StyledSpan = styled.span`
  color: #cbb26a;
`

const Cookies = () => {
  return (
    <Layout>
      <Seo title="Ciasteczka" />
      <CookiesWrapper>
        <StyledH1>Polityka Cookies</StyledH1>
        <StyledP>
          Poniższa Polityka Cookies określa zasady zapisywania i uzyskiwania
          dostępu do danych na Urządzeniach Użytkowników korzystających z
          Serwisu do celów świadczenia usług drogą elektroniczną przez
          Administratora Serwisu.
        </StyledP>

        <StyledH2>§ 1 Definicje</StyledH2>
        <StyledUl>
          <StyledLi>
            <StyledP>
              <strong>Serwis</strong> - serwis internetowy działający pod
              adresem{" "}
              <span id="serwis">
                <StyledSpan class="colored">
                  www.higienistkamagdalena.pl
                </StyledSpan>
              </span>
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Serwis zewnętrzny</strong> - serwisy internetowe
              partnerów, usługodawców lub usługobiorców Administratora
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Administrator</strong> - właściciel Serwisu{" "}
              <span id="firma">
                <StyledSpan>Higienistka Magdalena</StyledSpan>, świadczący
                usługi drogą elektroniczną za pośrednictwem Serwisu oraz
                przechowujący i uzyskujący dostęp do informacji w urządzeniach
                Użytkownika
              </span>
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Użytkownik</strong> - osba fizyczna, dla której
              Administrator świadczy usługi drogą elektroniczna za pośrednictwem
              Serwisu.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Urządzenie</strong> - elektroniczne urządzenie wraz z
              oprogramowaniem, za pośrednictwem, którego Użytkownik uzyskuje
              dostęp do Serwisu
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w
              formie plików zamieszczanych na Urządzeniu Użytkownika
            </StyledP>
          </StyledLi>
        </StyledUl>
        <StyledH2>§ 2 Rodzaje Cookies</StyledH2>
        <StyledUl>
          <StyledLi>
            <StyledP>
              <strong>Cookies wewnętrzne</strong> - pliki zamieszczane i
              odczytywane z Urządzenia Użytkownika przes system
              teleinformatyczny Serwisu
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Cookies zewnętrzne</strong> - pliki zamieszczane i
              odczytywane z Urządzenia Użytkownika przez systemy
              teleinformatyczne Serwisów zewnętrznych
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Cookies sesyjne</strong> - pliki zamieszczane i
              odczytywane z Urządzenia Użytkownika przez Serwis lub Serwisy
              zewnętrzne podczas jednej sesji danego Urządzenia. Po zakończeniu
              sesji pliki są usuwane z Urządzenia Użytkownika.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane
              z Urządzenia Użytkownika przez Serwis lub Serwisy zewnętrzne do
              momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie
              po zakończeniu sesji Urządzenia chyba że konfiguracja Urządzenia
              Użytkownika jest ustawiona na tryb usuwanie plików Cookie po
              zakończeniu sesji Urządzenia.
            </StyledP>
          </StyledLi>
        </StyledUl>

        <StyledH2>§ 3 Bezpieczeństwo</StyledH2>
        <StyledUl>
          <StyledLi>
            <StyledP>
              <strong>Mechanizmy składowania i odczytu</strong> - Mechanizmy
              składowania i odczytu Cookies nie pozwalają na pobierania
              jakichkolwiek danych osobowych ani żadnych informacji poufnych z
              Urządzenia Użytkownika. Przeniesienie na Urządzenie Użytkownika
              wirusów, koni trojańskich oraz innych robaków jest praktynie
              niemożliwe.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Cookie wewnętrzne</strong> - zastosowane przez
              Administratora Cookie wewnętrzne są bezpieczne dla Urządzeń
              Użytkowników
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>Cookie zewnętrzne</strong> - za bezpieczeństwo plików
              Cookie pochodzących od partnerów Serwisu Administrator nie ponosi
              odpowiedzialności. Lista partnerów zamieszczona jest w dalszej
              części Polityki Cookie.
            </StyledP>
          </StyledLi>
        </StyledUl>

        <StyledH2>§ 4 Cele do których wykorzystywane są pliki Cookie</StyledH2>
        <StyledUl id="cele">
          <StyledLi>
            <StyledP>
              <strong>
                <span class="colored">
                  Usprawnienie i ułatwienie dostępu do Serwisu
                </span>
              </strong>{" "}
              - Administrator może przechowywać w plikach Cookie informacje o
              prefernecjach i ustawieniach użytkownika dotyczących Serwisu aby
              usprawnić, polepszyć i przyśpieszyć świadczenie usług w ramach
              Serwisu.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>
                <span class="colored">Dane statystyczne</span>
              </strong>{" "}
              - Administrator <span id="sz4">oraz Serwisy zewnętrzne</span>{" "}
              wykorzystuje pliki Cookie do zbierania i przetwarzania danych
              statystycznych takich jak np. statystyki odwiedzin, statystyki
              Urządzeń Użytkowników czy statystyki zachowań użytkowników. Dane
              te zbierane są w celu analizy i ulepszania Serwisu.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              <strong>
                <span class="colored">Usługi społecznościowe</span>
              </strong>{" "}
              - Administrator oraz Serwisy zewnętrzne wykorzystują pliki Cookie
              do wsparcia usług społecznościowych
            </StyledP>
          </StyledLi>
        </StyledUl>
        <StyledH2>§ 5 Serwisy zewnętrzne</StyledH2>
        <StyledP id="zewinfo">
          <span class="colored">
            Administrator nie współpracuje z serwisami zewnętrznymi i Serwis nie
            umieszcza ani nie korzysta z żadnych plików zewnętrznych plików
            Cookie.
          </span>
        </StyledP>
        <StyledUl id="zewnetrzne"></StyledUl>
        <StyledH2>
          § 6 Możliwości określania warunków przechowywania i uzyskiwania
          dostępu na Urządzeniach Użytkownika przez Serwis i Serwisy zewnętrzne
        </StyledH2>
        <StyledUl>
          <StyledLi>
            <StyledP>
              Użytkownik może w dowolnym momencie, samodzielnie zmienić
              ustawienia dotyczące zapisywania, usuwania oraz dostępu do danych
              zapisanych plików Cookies
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              Informacje o sposobie wyłączenia plików Cookie w
              najpopularniejszych przeglądarkach komputerowych i urządzeń
              mobilnych dostępna są na stronie:{" "}
              <a href="http://jakwylaczyccookie.pl">jak wyłączyć cookie</a>.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do
              tej pory pliki Cookie korzystając z narzędzi Urządzenia
              Użytkownika za pośrednictwem którego Użytkowanik korzysta z usług
              Serwisu.
            </StyledP>
          </StyledLi>
        </StyledUl>

        <StyledH2>§ 7 Wyłączenie odpowiedzialności</StyledH2>
        <StyledUl>
          <StyledLi>
            Administrator stosuje wszelkie możliwe środki w celu zapewnienia
            bezpieczeństwa danych umieszczanych w plikach Cookie. Należy jednak
            zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych zależy od
            obu stron, w tym działalności Użytkownika oraz satnu zabezpieczeń
            urządzenia z którego korzysta.
          </StyledLi>
          <StyledLi>
            Administrator nie bierze odpowiedzialności za przechwycenie danych
            zawartych w plikach Cookie, podszycie się pod sesję Użytkownika lub
            ich usunięcie, na skutek świadomej lub nieświadomej działalność
            Użytkownika, wirusów, koni trojańskich i innego oprogramowania
            szpiegującego, którymi może być zainfekowane Urządzenie Użytkownika.
          </StyledLi>
          <StyledLi>
            Użytkownicy w celu zabezpieczenia się przed wskazanymi w punkcie
            poprzednim zagrożeniami powinni stosować się do{" "}
            <span id="cyber_random">
              zasad{" "}
              <a href="https://nety.pl/cyberbezpieczenstwo/">
                cyberbezpieczeństwa
              </a>
            </span>
            .
          </StyledLi>
          <StyledLi>
            Usługi świadczone przez podmioty trzecie są poza kontrolą
            Administratora. Podmioty te mogą w każdej chwili zmienić swoje
            warunki świadczenia usług, cel oraz wykorzystanie plików cookie.
            Administrator nie odpowiada na tyle na ile pozwala na to prawo za
            działanie plików cookies używanych przez serwisy partnerskie.
            Użytkownicy w każdej chwili mogą samodzielnie zarządzać zezwoleniami
            i ustawieniami plików cookie dla każedej dowolnej witryny.
          </StyledLi>
        </StyledUl>

        <StyledH2>§ 8 Wymagania Serwisu</StyledH2>
        <StyledUl>
          <StyledLi>
            <StyledP>
              Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu
              Użytkownika może spowodować nieprawidłowe działanie niektórych
              funkcji Serwisu.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo
              działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w
              jakikolwiek sposób możliwość zapisywania i odczytu plików Cookie.
            </StyledP>
          </StyledLi>
        </StyledUl>
        <StyledH2>§ 9 Zmiany w Polityce Cookie</StyledH2>
        <StyledUl>
          <StyledLi>
            <StyledP>
              Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej
              Polityki Cookie bez konieczności informowania o tym użytkowników.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              Wprowadzone zmiany w Polityce Cookie zawsze będą publikowane na
              tej stronie.
            </StyledP>
          </StyledLi>
          <StyledLi>
            <StyledP>
              Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki
              Cookie.
            </StyledP>
          </StyledLi>
        </StyledUl>
      </CookiesWrapper>
    </Layout>
  )
}

export default Cookies
